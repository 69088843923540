.footer-buttons {
  justify-content: space-between;
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .footer-buttons {
    justify-content: center;
    flex-wrap: wrap;
  }
}
/* trigger deployment */
::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
  height: 7px;
}
::-webkit-scrollbar-track {
  background: rgba(228, 221, 221, 0.562); /* color of the tracking area */
}
::-webkit-scrollbar-thumb {
  background-color: #6e23cf; /* color of the scroll thumb */
  border-radius: 15px;
}

.no-scrollbar {
  overflow-y: auto;
  scrollbar-width: none;
}
.responsive-card {
  width: 60%;
}
@media screen and (max-width: 1200px) {
  .responsive-card {
    width: 90% !important;
  }
}

@media screen and (max-width: 1200px) {
  .responsive-image {
    width: 66px !important;
    height: 86px !important;
  }
}
@media screen and (max-width: 1200px) {
  .responsive-button {
    font-size: 14px !important;
  }
}

.is-danger {
  color: red; /* Makes the text red */
}

.date-picker input[type="date"] {
  font-size: 16px;
  padding: 10px;
  border: 2px solid #4caf50; /* Color similar to the one in your image */
  border-radius: 4px;
  display: inline-block;
  font-family: "Arial", sans-serif;
}

/** I2T CSS **/
.top-pg-wrapper {
  height: 100vh;
}
.page-mn-wrapper {
  max-height: 70vh;
  min-height: 65vh;
}
/*Media Queries*/
@media screen and (max-width: 480px) {
  .addressdetails-page-wrapper,.billingaddress-page-wrapper,.ordersummary-page-wrapper {
    .page-mn-wrapper {
      width: 90%;

      .my-prescription-holder,.my-billing-holder,.my-order-summary-holder {
        width: 100%;
        .my-os-holder-inner{
          width: 100%;
        }
      }
    }
  }
  /* This css was added to fix the scroll bug of safari */
  .addressdetails-page-wrapper,.billingaddress-page-wrapper,.ordersummary-page-wrapper,.questioner-page-wrapper {
    height: 100%;
    .page-mn-wrapper {
      max-height: 100%;
      min-height: 100%;
    }
    .footer-buttons{
      margin-top: 5%;
      margin-bottom: 5%;
    }
  }
}
